import { getFormDefinition, createFormDataFromItem, createItemFromFormData } from './form';
import { entityToStore } from '@/utils/store';
import baseCrudMixin from '@/mixins/crud';
import { tryGetValue } from '@/utils';
import { Roles } from './Roles/Roles.js';
import { AgreementActionOption } from './AgreementByActions/AgreementByActions.js';
import { hasEditPermissionForCode } from '@/utils/permission';
export default {
  computed: {
    definition() {
      return this.createDefinition();
    },
    entityStore() {
      return entityToStore(this.entity);
    }
  },
  mixins: [baseCrudMixin],
  data: () => ({
    entity: 'Loan',
    returnTo: 'Loans'
  }),
  methods: {
    getDependencies(data) {
      return [
        { entity: 'applicant' },
        { entity: 'loanType' },
        { entity: 'status' },
        { entity: 'reason' },
        { entity: 'delay' },
        { entity: 'debtStep' },
        { entity: 'reasonDenial' },
        { entity: 'withdrawalApplicant' },
        { entity: 'withdrawalPv' },
        { entity: 'referalMunicipality' },
        { entity: 'referalNoordzij' }
      ];
    },
    createDefinition() {
      return getFormDefinition(this);
    },
    hasConsultantPermission() {
      return hasEditPermissionForCode('AgreementConsultant', this.$store.getters['permissions']);
    },
    hasManagerPermission() {
      return hasEditPermissionForCode('AgreementManager', this.$store.getters['permissions']);
    },
    hasBoardPermission() {
      return hasEditPermissionForCode('AgreementBoard', this.$store.getters['permissions']);
    },
    createFormDataFromItem,
    createItemFromFormData,
    async fetchById(id, vue) {
      const loan = await vue.$store.dispatch('loan/getItem', {
        selector: { id }
      });
      const dossier = await vue.$store.dispatch('dossier/getItem', {
        selector: { id: loan.dossierID }
      });
      const params = {};
      params.query = { loanid: id };
      await vue.$store.dispatch('loanagreement/getItems', params).then(data => {
        if (!data) {
          console.log('Geen loanagreements gevonden');
        } else {
          console.log('Loanagreements gevonden');
        }
        vue.loanAgreements = tryGetValue(data, 'items') || [];
      });
      const status = await vue.$store.dispatch('status/getItem', { selector: { id: loan.statusID } });
      vue.returntopage = this.returnTo;
      vue.statusCode = status.code;
      vue.loanId = +id;
      vue.headerCardInfo = dossier;
      vue.loanInfo = loan;
      vue.description = loan.displayName;
      const amountIsUnderAgreementLimit = loan.amount < loan.loanType.agreementLimit;
      let lastAgreementBy = Roles.None;
      const showBoard =
        (!amountIsUnderAgreementLimit &&
          this.loanAgreements[0] &&
          this.loanAgreements[0].agreementAction === AgreementActionOption.Approve) ||
        (this.loanAgreements[0] && this.loanAgreements[0].agreementAction === AgreementActionOption.Reject);
      if (this.loanAgreements.length !== 0) {
        lastAgreementBy = this.loanAgreements[0].agreementBy;
      }
      switch (lastAgreementBy) {
        case Roles.None:
          console.log(`Lening ter beoordeling Consultant met status:\n${loan.status.name}`);
          vue.allowSaveConsultant = true;
          vue.agreementby = Roles.Consultant;
          break;
        case Roles.Consultant:
          console.log(`Lening ter beoordeling manager met status:\n${loan.status.name}`);
          vue.allowSaveManager = true;
          vue.allowSaveConsultant = false;
          vue.agreementby = Roles.Manager;
          if (showBoard) {
            vue.showBoardAgreement = true;
            vue.agreementby = Roles.Manager;
          }
          break;
        case Roles.Manager:
          if (showBoard) {
            console.log(`Lening ter beoordeling bestuur met status\n${loan.status.name}`);
            vue.allowSaveBoard = true;
            vue.agreementby = Roles.Board;
            vue.showBoardAgreement = true;
            vue.allowSaveConsultant = false;
          } else {
            console.log(`Lening met ter kennisneming met status:\n${loan.status.name}`);
            vue.showBoard = true;
            vue.allowSaveConsultant = false;
          }
          break;
        case Roles.Board:
          console.log(`Lening met status:\n${loan.status.name}`);
          vue.allowSaveConsultant = false;
          vue.showBoardAgreement = true;
          break;
        default:
          console.log('Soort lening niet gevonden');
          vue.allowSaveConsultant = false;
          break;
      }

      vue.allowSaveConsultant &= this.hasConsultantPermission();
      vue.allowSaveManager &= this.hasManagerPermission();
      vue.allowSaveBoard &= this.hasBoardPermission();

      return this.createFormDataFromItem(this, loan);
    },
    actionFormatter(value) {
      const agreementAction = value.agreementAction;
      switch (agreementAction) {
        case AgreementActionOption.Approve:
          return this.$t('Actions.approve');
        case AgreementActionOption.Withdraw:
          return this.$t('Actions.withdraw');
        case AgreementActionOption.Reject:
          return this.$t('Actions.reject');
      }
      return value.agreementActionText;
    }
  }
};
